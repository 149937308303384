body {
  cursor: url('https://ionicframework.com/img/finger.png'), auto;
}

.bar.item-input-inset .item-input-wrapper input {
    width: 100%;
}

.search-wrapper-light {
    border-bottom: 2px solid rgba(255,255,255,0.5);
    background: none;
    border-radius: 0;
    margin-left: 5px;
    margin-right: 5px;
}

.search-wrapper-light input, .search-wrapper-light input::-webkit-input-placeholder {
    color: #ffffff;
}
